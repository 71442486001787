import {ImgTransition} from '~/components/kit/img-transition';
import {rem} from '~/shared/utils/style-helpers/units';
import cardBg from './assets/cosmic-case-card-bg.png'
import cardHeader from './assets/cosmic-case-game-header.svg'
import {AspectRatio} from '~/components/kit/aspect-ratio';
import {MouseEventHandler, useEffect, useRef, useState} from 'react';
import {CosmicCaseDescription} from './ui/cosmic-case-description';
import {CosmicCaseResultModal} from './ui/cosmic-case-result-modal';
import {useNavigate} from 'react-router-dom';
import {RoutePath} from '~/app/navigation/routes';
import {DELAY_BETWEEN_KICKS, FINAL_KICK_IDX, useCosmicCaseModel} from './model';
import {formatNumber, toFloatCrystals} from '~/shared/utils/format/format-numbers';
import {useDockModel} from '~/entities/dock';
import {useUserQuery} from '~/entities/user';
import {animated, useSpring} from '@react-spring/web';
import {TooltipHint} from '~/components/kit/tooltip';
import {Typography} from '~/components/kit/typography';
import {useAppTheme} from '~/components/kit';
import {lunar} from '~/entities/lunar-loot';
import {RewardType} from '~/entities/rewards';
import {phases} from './cosmic-case-phases';
import bgPhase0 from './assets/stone-phases/cosmic-case-stone-phase-0.png';
import bgPhase1 from './assets/stone-phases/cosmic-case-stone-phase-1.png';
import bgPhase2 from './assets/stone-phases/cosmic-case-stone-phase-2.png';
import bgPhase3 from './assets/stone-phases/cosmic-case-stone-phase-3.png';
import bgPhase4 from './assets/stone-phases/cosmic-case-stone-phase-4.png';
import toolBg from './assets/cosmic-case-tool.png';

const backgrounds = [bgPhase0, bgPhase1, bgPhase2, bgPhase3, bgPhase4] as const;

const CARD_HEIGHT = 571;
const CARD_WIDTH = 335;

export function CosmicCaseHeader() {
  return (
    <AspectRatio ratio={304 / 160} css={{paddingTop: 0}}>
      <ImgTransition src={cardHeader} />
    </AspectRatio>
  )
}

export function CosmicCaseGame() {
  const theme = useAppTheme();
  const dockModel = useDockModel();
  const userQuery = useUserQuery();
  const dockQuery = dockModel.dockQuery;

  const [damageLevel, setDamageLevel] = useState(0);

  // TODO: Тут надо подумать получше и обсудить, как сделать это условие более очевидным
  // здесь я разрешаю отправить запрос на клейм бокса после совершения нужного удара
  const model = useCosmicCaseModel({ canClaim: damageLevel === FINAL_KICK_IDX });

  const canClick = useRef(true);
  const navigate = useNavigate();

  const hasClaimError = model.claimInfo.query.isError;
  const showModal = damageLevel >= 5 && model.claimInfo.query.isFetched;

  const rewardType = model.rewardType;

  const rewardAmountFormatters: Record<RewardType, Function> = {
    'crystal': (amount: number) => toFloatCrystals(amount),
    'lunar': (amount: number) => formatNumber(lunar(amount, { precision: 3 })),
    'roketo': (amount: number) => toFloatCrystals(amount)
  }

  const rewardAmount = rewardAmountFormatters[rewardType](model.claimInfo.data.amount);

  const handleKickClick: MouseEventHandler<HTMLDivElement> = (e) =>{
    e.preventDefault();

    if (damageLevel <= 4 && canClick.current) {
      setDamageLevel((val) => val + 1);
      canClick.current = false;

      setTimeout(() => {
        canClick.current = true;
      }, DELAY_BETWEEN_KICKS)
    }
  }

  // Эту конструкцию приходится тут дергать, хотя по идее я делаю то же самое в модели при
  // удачном клейме, но там это не приводит к обновлению кошелька
  const updateBalances = async () => {
    await dockQuery.refetch();
    await userQuery.refetch();
  }

  const handleClaimClick = async () => {
    if (hasClaimError) {
      model.claimCase.mutateAsync()
    }

    await updateBalances();
    navigate(RoutePath.Earn);
  }

  const [toolSpringProps, toolSpringApi] = useSpring(
    () => ({
      transform: 'rotate(-28deg) translateX(0px)',
      config: phases[damageLevel].tool.animation.config,
    }),
    [damageLevel],
  );

  const [stoneSpringProps, stoneSpringApi] = useSpring(
    () => ({
      transform: 'translate(0, 0) scale(1) rotate(0deg)',
      config: phases[damageLevel].stone.animation.config,
    }),
    [damageLevel],
  );

  useEffect(() => {
    if (damageLevel) {
      const toolKeyframes = phases[damageLevel].tool.animation.keyframes;

      if (toolKeyframes) {
        toolKeyframes.forEach((frame, index) => {
          setTimeout(() => {
            toolSpringApi.start(frame);
          }, index * 200);
        });
      }

      const stoneKeyframes = phases[damageLevel].stone.animation.keyframes;

      if (stoneKeyframes) {
        stoneKeyframes.forEach((frame) => {
          setTimeout(() => {
            stoneSpringApi.start(frame);
          }, 500);
        });
      }
    }
  }, [damageLevel, toolSpringApi]);

  return (
    <AspectRatio
      ratio={CARD_HEIGHT / CARD_WIDTH}
      css={{
        marginTop: rem(16),
        paddingTop: 0,
        position: 'relative',
        cursor: 'pointer'
      }}
      onClick={handleKickClick}
    >
      <ImgTransition
        src={cardBg}
        css={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          objectPosition: 'bottom center',
        }}
      />

      <div css={{ padding: rem(16) }}>
        <CosmicCaseHeader />
        <CosmicCaseDescription />

        {/* Pickaxe */}
        <div css={{
          position: 'absolute',
          top: rem(310),
          left: rem(190),
          right: 0,
          bottom: 0,
          zIndex: 3,
          opacity: phases[damageLevel].tool.opacity
        }}>
          <AspectRatio ratio={244 / 210} css={{maxWidth: 105}}>
            <animated.img
              src={toolBg}
              css={{
                objectFit: 'contain',
              }}
              style={toolSpringProps}
            />
          </AspectRatio>
        </div>

        {/* Stone */}
        <div css={{
          position: 'absolute',
          left: rem(80),
          bottom: rem(90),
          zIndex: 1,
        }}>
          <animated.div style={stoneSpringProps}>
            <AspectRatio ratio={210 / 177} css={{width: '40vw', maxWidth: 177}}>
              <ImgTransition
                src={backgrounds[damageLevel]}
                css={{
                  objectFit: 'contain',
                }}
              />

              {damageLevel === 0 && (
                <TooltipHint
                  isVisible={true}
                  tailPosition="top-center"
                  backgroundColor="#FDF278"
                  css={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: theme.colors.onSurface,
                    position: 'absolute',
                    left: rem(10),
                    top: rem(100),
                    padding: rem(20),
                    width: 'fit-content',
                    height: 0
                  }}
                >
                  <Typography.h6 css={{padding: 0}}>
                    Smash it!
                  </Typography.h6>
                </TooltipHint>
              )}
            </AspectRatio>
          </animated.div>
        </div>

        {showModal && (
          <CosmicCaseResultModal
            reward={rewardAmount}
            rewardType={rewardType}
            onClick={handleClaimClick}
            hasError={hasClaimError}
          />
        )}

      </div>

    </AspectRatio>
  )
}

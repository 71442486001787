import {RewardType} from '~/entities/rewards';
import {titleCollapsed} from '../typography';
import {rem} from '~/shared/utils/style-helpers';
import moonSvg from './assets/moon.svg';
import rokeSvg from './assets/roke.svg';
import crystalSvg from './assets/crystal.svg';

interface RewardValueProps extends React.HTMLProps<HTMLSpanElement> {
  value?: number | string;
  iconSize?: string;
  splitNumbers?: boolean;
  rewardType?: RewardType;
}

function splitBySymbol(value: string) {
  return (
    <div
      css={{
        display: 'inline',
      }}
    >
      {value.split('').map((char, index) => {
        if (char === '.' || char === ',') {
          return (
            <span
              key={index}
              css={{
                display: 'inline-block',
              }}
            >
              {char}
            </span>
          );
        }

        return (
          <span
            key={index}
            css={{
              display: 'inline-block',
              textAlign: 'center',
              width: index === 0 ? 'auto' : '1em',
              marginLeft: index === 0 ? '0' : '-0.2em',
              marginRight:
                index === value.length - 1 || index === 0 ? '0' : '-0.2em',
            }}
          >
            {char}
          </span>
        );
      })}
    </div>
  );
}

export function RewardValue({
  value,
  iconSize = '1em',
  splitNumbers,
  rewardType = 'lunar',
  ...rest
}: RewardValueProps) {
  const isLunar = rewardType === 'lunar';

  const typeToIcon: Record<RewardType, any> = {
    'crystal': crystalSvg,
    'lunar': moonSvg,
    'roketo': rokeSvg
  }

  const icon = typeToIcon[rewardType];

  if (!value) {
    return (
      <span {...rest}>
        <img
          src={icon}
          alt='Reward token icon'
          css={{
            width: iconSize,
            height: iconSize,
            verticalAlign: 'top',
          }}
        />
      </span>
    );
  }
  return (
    <span
      {...rest}
      css={[
        titleCollapsed,
        {
          display: 'inline-flex',
          alignItems: 'center',
          fontVariantNumeric: 'oldstyle-nums tabular-nums slashed-zero',
          fontFeatureSettings: `'titl' on, 'case' on`,
          verticalAlign: 'top',
        },
      ]}
    >
      <span
        css={{
          lineHeight: 1,
        }}
      >
        {splitNumbers ? splitBySymbol(value.toString()) : value}
      </span>

      <img
        src={icon}
        alt='Reward token icon'
        css={{
          width: iconSize,
          height: iconSize,
          marginLeft: isLunar ? '0.1em' : rem(2),
          marginTop: isLunar ? '0em' : rem(-2),
          verticalAlign: 'top',
        }}
      />
    </span>
  );
}

import {rem} from '~/shared/utils/style-helpers';
import {RibbonIcon} from './ribbon-icon';
import {useAppTheme} from '../theme';

export const RibbonVariants = {
  new: { text: 'New', bgColor: '#F61252', color: '#FFF'},
  done: { text: 'Done!', bgColor: '#9EDDBB', color: '#08061F'},
  soon: { text: 'Soon...', bgColor: '#D1D1D1', color: '#08061F'},
} as const;

export function Ribbon({
  variant,
  show = true,
  ...props
}: {
  variant: keyof typeof RibbonVariants;
  show?: boolean;
} & React.HTMLProps<HTMLDivElement>) {
  const theme = useAppTheme();

  return (
    <div
      css={{
        transform: 'rotate(15deg)',
        transformOrigin: '50%',
        position: 'absolute',
        top: rem(10),
        right: rem(-10),
        visibility: show ? 'visible' : 'hidden',
        padding: rem(5),
        minWidth: rem(65),
        paddingRight: rem(10),
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        fontWeight: 700,

        filter: variant === 'soon' ? 'grayscale(1)' : 'none',
      }}
      {...props}
    >
      <RibbonIcon
        variant={variant}
        css={{
          position: 'absolute',
          top: rem(2),
          left: rem(-1),
          width: '100%',
          height: '100%',
        }}
      />
      <span
        css={{
          position: 'relative',
          color: RibbonVariants[variant].color || theme.colors.onSurface,
        }}
      >
        {RibbonVariants[variant].text}
      </span>
    </div>
  );
}

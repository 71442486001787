export const phases = [
  // Kick 1
  {
    tool: {
      animation: {
        config: { tension: 200, friction: 20, duration: 200 },
        keyframes: [
          { transform: 'rotate(-10deg) translateX(0px)' },
          { transform: 'rotate(-80deg) translateX(0px)' },
          { transform: 'rotate(-28deg) translateX(0px)' },
        ]
      },
      opacity: 1,
    },
    stone: {
      animation: {
        config: { tension: 200, friction: 20, duration: 200, delay: 200 },
        keyframes: [
          { transform: 'translate(0, 0) scale(1) rotate(0deg)' },
        ]
      },
    },
    tooltip: {
      opacity: 1,
    }
  },
  // Kick 2
  {
    tool: {
      animation: {
        config: { tension: 250, friction: 20, duration: 200 },
        keyframes: [
          { transform: 'rotate(-10deg) translateX(0px)' },
          { transform: 'rotate(-80deg) translateX(0px)' },
          { transform: 'rotate(-28deg) translateX(0px)' },
        ]
      },
      opacity: 1,
    },
    stone: {
      animation: {
        keyframes: [
          { transform: 'translate(0, 0) scale(1) rotate(0deg)' },
        ]
      },
    },
    tooltip: {
      opacity: 0,
    }
  },
  // Kick 3
  {
    tool: {
      animation: {
        config: { tension: 300, friction: 20, duration: 200 },
        keyframes: [
          { transform: 'rotate(-10deg) translateX(0px)' },
          { transform: 'rotate(-80deg) translateX(0px)' },
          { transform: 'rotate(-28deg) translateX(0px)' },
        ]
      },
      opacity: 1,
    },
    stone: {
      animation: {
        keyframes: [
          { transform: 'translate(0, 0) scale(1) rotate(0deg)' },
        ]
      },
    },
    tooltip: {
      opacity: 0,
    }
  },
  {
    tool: {
      animation: {
        keyframes: [
          { transform: 'rotate(-10deg) translateX(0px)' },
          { transform: 'rotate(-80deg) translateX(0px)' },
          { transform: 'rotate(-28deg) translateX(0px)' },
        ]
      },
      opacity: 1,
    },
    stone: {
      animation: {
        config: { tension: 300, friction: 20, duration: 200 },
        keyframes: [
          { transform: 'translate(0, 0) scale(1) rotate(0deg)' },
        ]
      },
    },
    tooltip: {
      opacity: 0,
    }
  },
  {
    tool: {
      animation: {
        keyframes: [
          { transform: 'rotate(-10deg) translateX(0px)' },
          { transform: 'rotate(-80deg) translateX(0px)' },
          { transform: 'rotate(-28deg) translateX(0px)' },
          { transform: 'rotate(-28deg) translateX(300px)' },
        ]
      },
      opacity: 1,
    },
    stone: {
      animation: {
        config: { tension: 300, friction: 20, duration: 200},
        keyframes: [
          { transform: 'translate(-35px, 0px) scale(0.8) rotate(-8deg)' },
        ]
      },
    },
    tooltip: {
      opacity: 0,
    }
  },
  // Final kick
  {
    tool: {
      animation: {
        keyframes: [
          { transform: 'rotate(-10deg)  translateX(300px)' },
          { transform: 'rotate(-80deg) translateX(300px)' },
          { transform: 'rotate(-28deg) translateX(300px)' },
        ]
      },
      opacity: 0,
    },
    stone: {
      animation: {
        config: { tension: 300, friction: 20, duration: 200 },
        keyframes: [
          { transform: 'translate(-20px, -20px) scale(0.8)' },
        ]
      },
    },
    tooltip: {
      opacity: 0,
    }
  },
];

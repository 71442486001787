import {IAuthorizationResponse} from '~/api';
import {apiClient} from '~/api/shared/api-client';

export type DropResponseType = 'lunar-loot' | 'crystals' | 'roketo-tokens';

export interface IOpenCaseResponse {
  drop_type: DropResponseType;
  amount: number,
}

function openCase(
  token: IAuthorizationResponse,
) {
  return apiClient.post<IOpenCaseResponse>(
    '/boxes/',
    {},
    {
      headers: {
        Authorization: `Bearer ${token.access_token}`,
      },
    },
  );
}

export const OpenCaseApi = {
  openCase,
};

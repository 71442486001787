import {Fragment} from 'react';
import {RewardValue} from '~/components/kit';
import {Button} from '~/components/kit/button/button';
import {useAppTheme} from '~/components/kit/theme/theme'
import {TooltipHint} from '~/components/kit/tooltip';
import {Typography} from '~/components/kit/typography';
import {rem} from '~/shared/utils/style-helpers'
import {RewardType} from '~/entities/rewards';

export interface ICosmicCaseResultModal {
  reward: number | string | undefined;
  rewardType: RewardType;
  onClick: () => void;
  hasError?: boolean;
}

export function CosmicCaseResultModal({ reward, rewardType = 'crystal', onClick, hasError = false }: ICosmicCaseResultModal) {
  const theme = useAppTheme()

  const rewardTypeNames: Record<RewardType, string> = {
    'crystal': 'Moon Crystals',
    'lunar': 'Loonar Loot',
    'roketo': 'Roketo'
  }

  const rewardTokenName = rewardTypeNames[rewardType];

  return (
    <Fragment>
      <div
        css={{
          display: 'flex',
          backdropFilter: 'blur(10px)',
          width: '100%',
          height: '100%',
          position: 'absolute',
          left: 0,
          top: 0,
          zIndex: 1,
          overflow: 'hidden',
          borderRadius: 30
        }}
      ></div>

      <div css={{
        display: 'flex',
        position: 'absolute',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        top: rem(280),
        left: 0,
        width: '100%',
        gap: rem(20),
        zIndex: 2
      }}>
        <TooltipHint
          isVisible={true}
          tailPosition="bottom-center"
          backgroundColor="#FFFFFF"
          renderTail={false}
          css={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            color: theme.colors.onSurface,
            gap: rem(8),
          }}
        >
          <Typography.h3>
            {!hasError && (
              <RewardValue
                value={'+' + reward}
                iconSize={rem(32)}
                rewardType={rewardType}
                css={{
                  justifyContent: 'center',
                  flexDirection: 'row-reverse',
                  gap: rem(4),
                  width: '100%',
                  fontWeight: 800,
                  marginBottom: rem(4)
                }}
              />
            )}

            <div>
              {hasError && (
                <span>Oops! Something<br />went wrong.</span>
              )}

              {!hasError && `You got ${rewardTokenName}!`}
            </div>
          </Typography.h3>

          {!hasError && (
            <Typography.body css={{ opacity: 0.5 }}>
              Your balance will be increased
            </Typography.body>
          )}

          <Button
            color='pink'
            css={{
              position: 'absolute',
              bottom: rem(-80)
            }}
            onClick={onClick}
          >
            {!hasError ? 'Claim Reward' : 'Try again'}
          </Button>
        </TooltipHint>

      </div>
    </Fragment>
  )
}

import {useNavigate} from 'react-router-dom';
import {Header} from '~/app/header';
import {RoutePath} from '~/app/navigation/routes';
import {PageLayout} from '~/components/layouts/page-layout';
import {CosmicCaseGame} from '~/features/cosmic-case';

export function CosmicCasePage() {
  const navigate = useNavigate();

  return (
    <PageLayout>
      <Header onClickBack={() => navigate(RoutePath.Earn)}/>
      <CosmicCaseGame/>
    </PageLayout>
  );
}

import React from 'react';
import { RibbonVariants } from './ribbon';

export const RibbonIcon = ({
  variant,
  ...props
}: React.HTMLProps<HTMLDivElement> & {
  variant: keyof typeof RibbonVariants;
}) => {
  const backgroundColor = RibbonVariants[variant].bgColor;

  return (
    <div {...props}>
      <svg
        width="65"
        height="29"
        viewBox="0 0 65 29"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d_21_265)">
          <path
            d="M0.656494 5.12583C0.656494 2.58366 2.71733 0.522827 5.2595 0.522827H60.8784C62.491 0.522827 63.6036 2.13821 63.0287 3.6448L60.0774 11.3795C59.775 12.1721 59.775 13.0483 60.0774 13.8409L63.0287 21.5757C63.6036 23.0822 62.491 24.6976 60.8784 24.6976H5.2595C2.71733 24.6976 0.656494 22.6368 0.656494 20.0946V5.12583Z"
            fill={backgroundColor}
          />
        </g>
        <defs>
          <filter
            id="filter0_d_21_265"
            x="0.656494"
            y="0.522827"
            width="63.6764"
            height="27.6271"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="1.15075" dy="3.45225" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_21_265"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_21_265"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </div>
  );
};

import {useMutation, useQuery} from '@tanstack/react-query';
import {IReward, IUserReward, RewardName, RewardsApi} from '~/api/rewards';
import {useAuthorizationToken} from '../user';
import {makeAxiosResponse} from '~/api/shared/api-client';
import {trackSocialRewardClaim} from '~/features/rewards';
import {signal} from '@preact/signals-react';
import {useDockModel} from '../dock';

export const TASK_TYPES = {
  PARTNER_TASK: 'partner-task',
};

export type RewardType = 'lunar' | 'crystal' | 'roketo';

export class RewardsPageModel {
  rewards: IReward[];
  userRewards: IUserReward[];

  constructor(rewardsData: IReward[], userRewardsData: IUserReward[]) {
    this.rewards = rewardsData;
    this.userRewards = userRewardsData;
  }

  isClaimed(name: RewardName): boolean {
    const id = this.getRewardByName(name)?.id;

    const userReward = this.userRewards.find(
      (userReward) => userReward.reward_id === id,
    );

    if (!userReward) {
      return false;
    }

    return userReward.redeemed;
  }

  getRewardByName(name: RewardName): IReward | undefined {
    const reward = this.rewards.find((reward) => reward.name === name);

    return reward;
  }

  getRewardById(id: number): IReward | undefined {
    const reward = this.rewards.find((reward) => reward.id === id);

    return reward;
  }

  userReward(name: RewardName): IUserReward | undefined {
    const id = this.getRewardByName(name)?.id;

    return this.userRewards.find((userReward) => userReward.reward_id === id);
  }

  get claimedRewards(): IReward[] {
    return this.rewards.filter((reward) => {
      return this.isClaimed(reward.name);
    });
  }

  get partnerTasks(): IReward[] {
    return this.rewards.filter((reward) => reward.type === 'partner-task');
  }

  get unclaimedPartnerTasks(): IReward[] {
    return this.partnerTasks.filter((task) => {
      const userReward = this.userReward(task.name);

      return !userReward || userReward.redeemed === false;
    });
  }

  get claimedPartnerTasks(): IReward[] {
    return this.partnerTasks.filter((task) => {
      const userReward = this.userReward(task.name);

      return userReward && userReward.redeemed === true;
    });
  }
}

export const userRewardsData = signal<IUserReward[] | null>(null);
export const rewardsData = signal<IReward[] | null>(null);

export function useRewards() {
  const token = useAuthorizationToken();
  const dock = useDockModel();

  const allRewardsQuery = useQuery({
    queryKey: ['all-rewards'],
    queryFn: async () => {
      const res = await RewardsApi.getAllRewards(token);
      rewardsData.value = res.data;

      return res;
    },
    refetchInterval: 0,
    staleTime: 2 * 60 * 1000,
  });

  const allRewardsQueryData = allRewardsQuery.data || makeAxiosResponse([]);

  const userRewardsQuery = useQuery({
    queryKey: ['user-rewards'],
    queryFn: async () => {
      const res = await RewardsApi.getUserRewards(token);
      userRewardsData.value = res.data;

      return res;
    },
    refetchInterval: 0,
    staleTime: 2 * 60 * 1000,
  });

  const userRewardsQueryData = userRewardsQuery.data || makeAxiosResponse([]);

  const model = new RewardsPageModel(
    allRewardsQueryData.data,
    userRewardsQueryData.data,
  );

  const claimRewardsMutation = useMutation({
    mutationKey: ['claim-reward'],
    mutationFn: async (data: {reward_id: number}) => {
      const reward = model.getRewardById(data.reward_id);
      if (reward) {
        trackSocialRewardClaim({
          rewardType: reward.name,
        });
      }

      const mutationResult = await RewardsApi.claimRewards(data, token);
      return mutationResult;
    },
    onSuccess: () => {
      userRewardsQuery.refetch();
      dock.dockQuery.refetch();
    },
  });

  const hasReward = (name: RewardName) => {
    return model.getRewardByName(name) !== undefined;
  };

  return {
    model,
    allRewardsQuery,
    userRewardsQuery,
    claimRewardsMutation,
    isReady: allRewardsQuery.isSuccess && userRewardsQuery.isSuccess,
    hasReward,
  };
}

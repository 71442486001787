import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import {useAuthorizationToken, useUserQuery} from '~/entities/user';
import {isQueryReady} from '~/shared/utils/query';
import {DropResponseType, IOpenCaseResponse, OpenCaseApi} from './api/cosmic-case-api';
import {useDockModel} from '~/entities/dock';
import {RewardType} from '~/entities/rewards';

export const COSMIC_CASE_PRICE = 5;
export const DELAY_BETWEEN_KICKS = 1000;
export const FINAL_KICK_IDX = 4;

export const mapDropTypeToRewardType: Record<DropResponseType, RewardType> = {
  'crystals': 'crystal',
  'lunar-loot': 'lunar',
  'roketo-tokens': 'roketo'
}

export function useCosmicCaseModel({ canClaim }: { canClaim: boolean }) {
  const token = useAuthorizationToken();
  const queryClient = useQueryClient();
  const dockModel = useDockModel();
  const dockQuery = dockModel.dockQuery;
  const userQuery = useUserQuery();

  const claimCaseInfo = useQuery({
    queryKey: ['cosmic-case'],
    enabled: canClaim,
    queryFn: () => {
      return OpenCaseApi.openCase(token);
    },
  });

  const data: IOpenCaseResponse = claimCaseInfo.data?.data || {
    amount: 0,
    drop_type: 'crystals'
  };

  const claimCase = useMutation({
    mutationKey: ['cosmic-case'],
    mutationFn: () => {
      return OpenCaseApi.openCase(token);
    },
    onSuccess: (data) => {
      queryClient.setQueryData(['cosmic-case'], data);
      dockQuery.refetch();
      userQuery.refetch();
    },
  });

  const rewardType = mapDropTypeToRewardType[data.drop_type];

  return {
    isReady: isQueryReady(claimCaseInfo),
    claimInfo: {
      query: claimCaseInfo,
      data,
    },
    rewardType,
    claimCase,
  };
}
